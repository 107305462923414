@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-no-repeat bg-cover bg-center;
}




